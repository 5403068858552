import React, { useEffect, useState } from "react";
import * as QrReader from "modern-react-qr-reader";
import * as CryptoJS from "crypto-js";
import logo from "./assets/logo.png";
import check from "./assets/check.svg";
import wrong from "./assets/wrongg.svg";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getDatabase, onValue, push, ref, set } from "firebase/database";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCX_e2PsmRBtXNVoNwYlj9zLBFNk5lHKSk",
    authDomain: "chira-qr-scanner.firebaseapp.com",
    projectId: "chira-qr-scanner",
    storageBucket: "chira-qr-scanner.appspot.com",
    messagingSenderId: "48675072820",
    appId: "1:48675072820:web:9c9d332d3c4c7bf40a5361",
    measurementId: "G-YTNJP7L078",
    databaseURL:
        "https://chira-qr-scanner-default-rtdb.europe-west1.firebasedatabase.app",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

const App = () => {
    const [data, setData] = useState<string>();
    const [isScanning, setIsScanning] = useState(false);
    const [isValid, setIsValid] = useState<boolean>();

    useEffect(() => {
        if (data) {
            console.log(data);
            setIsScanning(false);
            if (checkQRData(data)) {
                setIsValid(true);
                saveEvent(data, "successful_scan");
            } else {
                setIsValid(false);

                saveEvent(data, "failed_scan");
            }
        }
    }, [data]);

    useEffect(() => {
        const scansCountRef = ref(
            database,
            "successful_scan" + new Date().getDate()
        );
        onValue(scansCountRef, (snapshot) => {
            const data = snapshot.val();
            console.log(data);
            set(
                ref(database, "successful_count" + new Date().getDate()),
                Object.keys(data)?.length
            );
        });
    }, []);

    const saveEvent = async (data: any, eventName: string) => {
        try {
            logEvent(analytics, eventName, {
                data,
                time: new Date().toLocaleString(),
            });
            const newEv = push(ref(database, eventName + new Date().getDate()));
            set(newEv, {
                data,
                time: new Date().toLocaleString(),
            });
            console.log("Added", data);
        } catch (error) {
            console.log(error);
        }
    };

    const checkQRData = (ciphertext: string) => {
        try {
            const passphrase = "1nG0dW3Trust";
            const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);

            console.log(originalText);

            return originalText.includes("Tribus");
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    return (
        <div className="wrapper">
            <img src={logo} />
            <span className="info">Invitation Scanner</span>
            {!isScanning && (
                <>
                    <div className="inTheMiddle">
                        {isValid !== undefined && (
                            <div className="response-container">
                                <img src={isValid ? check : wrong} />
                                <span className="response">{`Code is ${
                                    isValid ? "valid" : "invalid"
                                }`}</span>
                            </div>
                        )}
                        <div className="animated-button">
                            <button
                                className="button-73"
                                onClick={() => setIsScanning(true)}
                            >
                                Press to scan
                            </button>
                        </div>
                    </div>
                </>
            )}
            {isScanning && (
                <>
                    <QrReader
                        delay={300}
                        facingMode={"environment"}
                        onScan={(data: any) => setData(data)}
                        onError={(err: any) => console.log(err)}
                        style={{ width: "100%" }}
                    />
                    <div
                        className="close-button"
                        onClick={() => {
                            setIsValid(undefined);
                            setIsScanning(false);
                        }}
                    >
                        <span>Close scanner</span>
                    </div>
                </>
            )}
        </div>
    );
};

export default App;
